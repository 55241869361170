import { PartialRouteObject } from "react-router";

import Authenticated from "src/components/Shared/Authenticated";

import BaseLayout from "src/layouts/BaseLayout";
import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";

// Unauthed routes
import accountRoutes from "./account";
import baseRoutes from "./base";

// Authed routes
import dashboardsRoutes from "./dashboards";
import teamRoutes from "./team";
import accountManagementRoutes from "./accountManagement";
import {campaignRoutes, nonPaidCampaignRoutes} from "./campaignOverview";
import friendvertiserRoutes from "./friendvertiser";

const router: PartialRouteObject[] = [
  {
    path: "account",
    children: accountRoutes,
  },
  {
    path: "*",
    element: <BaseLayout />,
    children: baseRoutes,
  },
  {
    path: "/",
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: "/",
        children: dashboardsRoutes,
      },
      {
        path: "/team",
        children: teamRoutes,
      },
      {
        path: "/account-management",
        children: accountManagementRoutes,
      },
      {
        path: "/campaign",
        children: campaignRoutes,
      },
      {
        path: "/non-paid-campaign",
        children: nonPaidCampaignRoutes,
      },
      {
        path: "/friendvertiser-overview",
        children: friendvertiserRoutes,
      },
    ],
  },
];

export default router;
