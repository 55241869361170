import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import {
  createDynamicLink,
  getReferrals,
  IReferral,
  DynamicLinkResponse, 
  Friendvertiser, 
  getLinkStats,
  DynamicAutoList
} from "src/api/dynamicLink";
import { setAlertMessage } from "./alert";
import { ClientTeam } from "src/models/clientTeam";
import { getClientTeams } from "src/api/clientTeam";
import { LinkEventStat } from "src/models/dynamicLink";

type GroupedStat = {
  [key in LinkEventStat["platform"]]: LinkEventStat[];
};

interface DynamicLinkState {
  loading: boolean;
  referalsLoading: boolean;
  linkStatsLoading: boolean;
  error: string;
  referalResult: IReferral[];
  filteredOrganisations: ClientTeam[];
  isModalOpen: boolean;
  linkData: IReferral[];
  groupedStat: GroupedStat;
  friendvertiserList: Friendvertiser[];
  linkStatsRes: DynamicAutoList
}

const initialState: DynamicLinkState = {
  loading: false,
  referalsLoading: false,
  linkStatsLoading: false,
  error: '',
  referalResult: [],
  filteredOrganisations: [],
  isModalOpen: false,
  linkData: [],
  groupedStat: {} as GroupedStat,
  friendvertiserList: [],
  linkStatsRes: {
    friendvertiserReferralList: [],
    linkData: {
      linkEventStats: []
    }
  }
}

interface DynamicLinkInput {
  title: string,
  linkUrl: string,
  clientTeamId: string,
}

export const createDynamicLinkAsync = createAsyncThunk<DynamicLinkResponse, DynamicLinkInput>(
  "dynamicLink/createDynamicLink",
  async ({ title, linkUrl, clientTeamId }, thunkAPI) => {
    try {
      const response = await createDynamicLink({ title, linkUrl, clientTeamId })
      const data = response
      return data
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to create the dynamic link. Please try again later.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const getReferralsAsync = createAsyncThunk<IReferral[], string>(
  "dynamicLink/getReferrals",
  async (clientId, thunkAPI) => {
    try {
      const response = await getReferrals(clientId);
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to get referrals. Please try again later.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const fetchClientTeams = createAsyncThunk<ClientTeam[], number>(
  "dynamicLink/clientTeams/fetch",
  async (limit, thunkAPI) => {
    try {
      const response = await getClientTeams(limit);
      return response.clientTeamData.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.name === value.name)
      )
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to fetch client teams. Please try again later.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const getLinkStatsAsync = createAsyncThunk<DynamicAutoList, string>(
  "dynamicLink/getLinkStats",
  async (clientId, thunkAPI) => {
    try {
      const response = await getLinkStats(clientId);
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to get link stats. Please try again later.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
)


const dynamicLinkSlice = createSlice({
  name: 'dynamicLink',
  initialState,
  reducers: {
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload
    },
    setLinkData: (state, action: PayloadAction<IReferral[]>) => {
      state.linkData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //createDynamicLink
      .addCase(createDynamicLinkAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(createDynamicLinkAsync.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(createDynamicLinkAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // getReferrals
      .addCase(getReferralsAsync.pending, (state) => {
        state.referalsLoading = true;
      })
      .addCase(getReferralsAsync.fulfilled, (state, action) => {
        state.referalsLoading = false;
        state.referalResult = action.payload;
      })
      .addCase(getReferralsAsync.rejected, (state, action) => {
        state.referalsLoading = false;
        state.error = action.error.message;
      })
      // fetchClientTeams
      .addCase(fetchClientTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClientTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredOrganisations = action.payload
      })
      .addCase(fetchClientTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getLinkStats
      .addCase(getLinkStatsAsync.pending, (state) => {
        state.linkStatsLoading = true;
      })
      .addCase(getLinkStatsAsync.fulfilled, (state, action) => {
        state.linkStatsLoading = false;
        const result = action.payload;
        let ret: GroupedStat;
        if (result?.linkData?.linkEventStats) {
          ret = result?.linkData?.linkEventStats.reduce(function (r, a) {
            r[a.platform] = r[a.platform] || [];
            r[a.platform].push(a);
            return r;
          }, Object.create(null));
          state.groupedStat = ret;
        }
        if (result.friendvertiserReferralList[0]?.friendvertisers) {
          state.friendvertiserList =
            result.friendvertiserReferralList[0].friendvertisers;
        }
      })
      .addCase(getLinkStatsAsync.rejected, (state, action) => {
        state.linkStatsLoading = false;
        state.error = action.error.message;
      });
  }
})

export const reducer = dynamicLinkSlice.reducer
export const { setIsModalOpen, setLinkData } = dynamicLinkSlice.actions
export const selectReferrals = (state: RootState) => state.dynamicLink.referalResult
export const selectLoading = (state: RootState) => state.dynamicLink.loading
export const selectOrganisations = (state: RootState) => state.dynamicLink.filteredOrganisations
export const selectIsModalOpen = (state: RootState) => state.dynamicLink.isModalOpen
export const selectLinkData = (state: RootState) => state.dynamicLink.linkData
export const selectGroupedStat = (state: RootState) => state.dynamicLink.groupedStat
export const selectFriendvertiserList = (state: RootState) => state.dynamicLink.friendvertiserList
export const selectLinkStatsRes = (state: RootState) => state.dynamicLink.linkStatsRes
// loadingStates
export const selectReferalLoading = (state: RootState) => state.dynamicLink.referalsLoading
export const selectLinkStatLoading = (state: RootState) => state.dynamicLink.linkStatsLoading
