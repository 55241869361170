import { FC, ReactNode, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useDispatch } from 'src/store';
import { setDashboardHeader } from 'src/slices/dashboard';

import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  active?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  active,
  name,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);
  const dispatch = useDispatch();

  return (
    <ListItem component="div" key={name} {...rest}>
      <Button
        disableRipple
        activeClassName="Mui-active"
        component={RouterLink}
        onClick={() => {closeSidebar(); dispatch(setDashboardHeader(name));}}
        to={link}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  active: false
};

export default SidebarMenuItem;
