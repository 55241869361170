import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/Shared/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const TeamManagement = Loader(
    lazy(() => import('src/content/pages/Team/Management'))
);

const accountManagementRoutes = [
    {
      path: 'management',
      element: <TeamManagement />
    },
  ];

export default accountManagementRoutes;
