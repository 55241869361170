import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/Shared/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const FVOverview = Loader(
  lazy(() => import('src/content/pages/Friendvertisers/Overview'))
);
const friendvertiserRoutes = [
  {
    path: 'fv-overview/:id',
    element: <FVOverview />
  },
];

export default friendvertiserRoutes;
