import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import { CreateCampaign } from "src/models/campaign";

interface CampaignFormState {
  activeStep: number;
}

interface CampaignState {
  campaign: CreateCampaign;
  form: CampaignFormState;
}

const initialState: CampaignState = {
  form: { activeStep: 0 },
  campaign: {
    brand: "",
    campaignCategory: "",
    campaignAdvertiser: "",
    platform: "",
    campaignHeading: "",
    campaignSubHeading: "",
    productAvailability: "",
    productLink: "",
    postType: "",
    quizQuestion: "",
    quizOption1: "",
    quizOption2: "",
    quizOption3: "",
    quizOption4: "",
    quizAnswer: "",
    pollQuestion: "",
    pollOption1: "",
    pollOption2: "",
    friendvertiserInterests: [],
    friendvertiserInstructions: "",
    friendvertiserImages: [],
    targetAudience: [],
    targetGender: "",
    audienceLocation: [],
    campaignCurrency: "GBP",
    campaignBudget: undefined,
    campaignDurationStart: undefined,
    campaignDurationEnd: undefined,
  },
};

const slice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    updateCampaignState(state: CampaignState, action: PayloadAction<CreateCampaign>) {
      if (action.payload) {
        const campaign = action.payload;
        state.campaign = campaign;
      }
    },
    incrementActiveStep(state: CampaignState, action: PayloadAction<number>) {
      state.form.activeStep = action.payload;
    },
    resetForm(state: CampaignState) {
      state.form.activeStep = 0;
    },
  },
});

export const updateCampaignState =
  (data: CreateCampaign): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateCampaignState(data));
  };

export const setActiveStep =
  (step: number): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.incrementActiveStep(step));
  };

export const resetForm = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.resetForm());
};

export const reducer = slice.reducer;

export default slice;
