import axios from "axios";
import { WYSPR_WEBAPI_HOST, Response } from "../index";
import { Brand, BrandStatusEnum, IndustriesEnum } from "src/models/brand";
import { User } from "src/models/user";
import { ClientTeam } from "src/models/clientTeam";

export interface GetBrandsRequest {
  _id: string;
}

export interface GetBrandsResponse {
  message: {
    docs: Brand[];
    totalPages: number;
    page: number;
    totalDocs: number;
  };
}

export interface GetIndustriesResponse {
  message: {
    categories: IndustriesEnum[];
  };
}

export interface CreateBrandResponse {
  message: {
    brandData: Brand;
  };
}

export interface CreateBrandRequest {
  name: string;
  clientTeamId: string;
  industry: string;
  userId?: string;
}

export interface EditBrandRequest {
  name?: string;
  clientTeamId?: ClientTeam["_id"]
  industry?: string;
  organization?: ClientTeam["name"];
  userId?: string;
  brandState?: BrandStatusEnum.ACTIVE
}

export interface EditBrandResponse {
  message: {
    brandData: Brand;
  };
}

export interface GetBrandsInput {
  pageNumber?: number;
  limit?: number;
  order?: string;
  sortBy?: string;
}

export interface DeleteBrandResponse {
  message: {
    brandData: Brand;
  };
}

/*
  GET Used to list of brands linked to Client.
*/
export const getBrands = async ({
  pageNumber,
  limit,
  order,
  sortBy
}: GetBrandsInput): Promise<{
  brands: Brand[];
  pageInfo: { totalPages: number; currentPage: number; totalDocs: number };
}> => {
  const response: Response<GetBrandsResponse> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/brands`,
    {
      params: {
        showAll: true,
        page: pageNumber,
        limit: limit,
        showTeam: true,
        count: false,
        showDetail: true,
        order: order,
        sortBy: sortBy
      },
    }
  );

  const { docs, totalPages, page, totalDocs } = response.data.message;
  return {
    brands: docs,
    pageInfo: { currentPage: page, totalPages: totalPages, totalDocs: totalDocs },
  };
};

/*
  POST Used to create a new brand.
*/
export const createBrand = async (
  input: CreateBrandRequest
): Promise<Brand> => {
  const response: Response<CreateBrandResponse> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/brands`,
    input
  );
  return response.data.message.brandData;
};

/*
  PUT Used to edit a new brand.
*/
export const editBrand = async (
  brandId: Brand["_id"],
  input: EditBrandRequest
): Promise<Brand> => {
  const response: Response<EditBrandResponse> = await axios.put(
    `${WYSPR_WEBAPI_HOST}/brands/${brandId}`,
    input
  );
  return response.data.message.brandData;
};

/*
  DELETE Used to edit a new brand.
*/
export const deleteBrand = async (
  brandId: Brand["_id"],
  userId: User["_id"],
  teamId: ClientTeam["_id"]
): Promise<Brand> => {
  const response: Response<DeleteBrandResponse> = await axios.delete(
    `${WYSPR_WEBAPI_HOST}/brands/${brandId}`,
    {
      params: {
        userId: userId,
        teamId: teamId,
      },
    }
  );
  return response.data.message.brandData;
};

/*
  GET Used to list of industries.
*/
export const getIndustries = async (): Promise<{
  categories: IndustriesEnum[];
}> => {
  const response: Response<GetIndustriesResponse> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/interests`,
    {}
  );

  return response.data.message;
};
