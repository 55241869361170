import { 
    Alert,
    AlertColor,
    Box,
    IconButton,
    Fade 
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

export interface AlertMessageState {
    message: string,
    severity: AlertColor
}

const AlertMessage = (props: {showAlertMessage: boolean, alertMessage: AlertMessageState, setShowAlertMessage: Function}) => {
    return (
        <Box 
            display="flex" 
            justifyContent="center"
            alignItems="center" 
            sx={{
                position: 'fixed',
                top: "90px",
                ml: "auto",
                mr: "auto",
                left: 0,
                right: 0,
                textAlign: "center",
                zIndex: props.showAlertMessage ? 99999 : -9,
            }}
        >
            <Fade in={props.showAlertMessage} timeout={500} unmountOnExit={false}>
                <Alert
                    icon={<InfoIcon fontSize="inherit" color="inherit" />}
                    severity={props.alertMessage.severity}
                    sx={{
                        color: "black",
                        mt: "5px",
                        mx: "40px",
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                props.setShowAlertMessage(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {props.alertMessage.message}
                </Alert>
            </Fade>
        </Box>
    )
}

export default AlertMessage;
