import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IUserDataAnalytics } from "src/models/insights";
import { analyticsInsights } from "src/api/insights";
import { RootState } from "src/store";
import { setAlertMessage } from "./alert";
import { differenceInDays } from "date-fns";

export enum SliceStatusEnum {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED"
}

interface AnalyticsState {
  analytics: IUserDataAnalytics;
  status: SliceStatusEnum;
  error: string;
  dateRangeFilter: number;
}

const firstWysprUsers = new Date("2022-07-01");
const dateRange = differenceInDays(new Date(), firstWysprUsers);

const initialState: AnalyticsState = {
  analytics: {
    accountType: {},
    activeCreatorCount: 0,
    autoVerifiedFvCount: 0,
    basicFvCount: 0,
    platformCount: {},
    fvsWithTikTok: 0,
    referralSource: [],
    signUpDate: {},
    totalAppUsage: 0,
    userRole: {},
    allTimeFvCount: 0
  },
  status: SliceStatusEnum.LOADING,
  error: null,
  dateRangeFilter: dateRange
};

export const fetchAnalytics = createAsyncThunk<IUserDataAnalytics, string>(
  "analytics/fetch",
  async (dateRange, thunkAPI) => {
    try {
      const analytics = await analyticsInsights(dateRange);
      return analytics;
    } catch (err) {
      thunkAPI.dispatch(
        setAlertMessage({
          message:
            "There was an error when trying to get the page data. Please try again later.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(err.message || "An error occurred");
    }
  }
);

const slice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setDateRangeFilter: (state, action) => {
      state.dateRangeFilter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.pending, (state) => {
        state.status = SliceStatusEnum.LOADING;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = SliceStatusEnum.SUCCEEDED;
        state.analytics = action.payload;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.status = SliceStatusEnum.FAILED;
        state.error = action.error.message;
      });
  }
});
export const reducer = slice.reducer;
export const selectAnalytics = (state: RootState) => state.analytics;
export const selectAnalyticsStatus = (state: RootState) =>
  state.analytics.status;
export const selectDateRangeFilter = (state: RootState) =>
  state.analytics.dateRangeFilter;
export const { setDateRangeFilter } = slice.actions;
