import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';

interface DashboardState {
  name: string,
  showWelcomeBox: boolean
}

const initialState: DashboardState = {
  name: "Dashboard",
  showWelcomeBox: true
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardHeader(state: DashboardState, action: PayloadAction<string>) {
      if (action.payload) {
        state.name = action.payload;
      }
    },
    setWelcomeBoxShow(state: DashboardState, action: PayloadAction<boolean>) {
      state.showWelcomeBox = action.payload;
    },
  }
});



export const setDashboardHeader =
  (data: any): AppThunk =>
    async (dispatch): Promise<void> => {
      dispatch(slice.actions.setDashboardHeader(data));
    };

export const setWelcomeBoxShow =
  (data: boolean): AppThunk =>
    async (dispatch): Promise<void> => {
      dispatch(slice.actions.setWelcomeBoxShow(data));
    };

export const reducer = slice.reducer;