import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/Shared/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);

const RegisterWizard = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Wizard'))
);

const InviteRegisterWizard = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Invite'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const StatusNotAccepted = Loader(
  lazy(() => import('src/content/pages/Status/StatusNotAccepted'))
);

const StatusNotClient = Loader(
  lazy(() => import('src/content/pages/Status/StatusNotClient'))
);

const HelpArticle = Loader(
  lazy(() => import('src/content/pages/Help'))
)

const accountRoutes = [
  {
    path: 'login',
    element: <LoginCover />
  },
  {
    path: 'register',
    element: <RegisterWizard />
  },
  {
    path: 'invite',
    element: <InviteRegisterWizard />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'notaccepted',
    element: <StatusNotAccepted />
  },
  {
    path: 'notclient',
    element: <StatusNotClient />
  },
  {
    path: 'help-article',
    element: <HelpArticle />
  }
];

export default accountRoutes;
