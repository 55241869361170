import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import LanguageSwitcher from './../Buttons/LanguageSwitcher';

import { useDispatch } from 'src/store';
import { setDashboardHeader } from 'src/slices/dashboard';

import {
  Avatar,
  Box,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const StyledListItemText = styled(ListItemText)(
  () => `
    padding-left: 12px,
  `
)

const UserAvatar = styled(Avatar)(
  () => `
        height: 90%;
        width: 90%;
        border-radius: 6px;
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 0;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const setDashboardHeaderName = () => {
    dispatch(setDashboardHeader("Account Management"));
    handleClose();
  }

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar id="user-avatar" alt={user.name} src={user.avatarUrl?.image96x96 ? user.avatarUrl?.image96x96 : user.avatarUrl?.image} />
      </UserBoxButton>
      <KeyboardArrowDownIcon onClick={handleOpen} style={{ cursor: "pointer" }} />
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Avatar variant="rounded" alt={user.name} src={user.avatarUrl?.image96x96 ? user.avatarUrl?.image96x96 : user.avatarUrl?.image} />
            <Box sx={{ display: "none" }}><LanguageSwitcher /></Box>
          </Box>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          <Link style={{ textDecoration: "none" }} to="/account-management/management?tab=0" onClick={setDashboardHeaderName}>
            <MenuItem>
              <PersonOutlineOutlinedIcon
                sx={{
                  color: "#000000",
                  opacity: 0.8,
                  mr: "12px"
                }}
              />
              <StyledListItemText
                primaryTypographyProps={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "#757575"
                }}
                primary={t('Account')}
              />
            </MenuItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/account-management/management?tab=1" onClick={setDashboardHeaderName}>
            <MenuItem>
              <GroupOutlinedIcon
                sx={{
                  color: "#000000",
                  opacity: 0.8,
                  mr: "12px"
                }}
              />
              <StyledListItemText
                primaryTypographyProps={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "#757575"
                }}
                primary={t('Team')}
              />
            </MenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={'/'}
            onClick={() => {window.location.href="https://www.wyspr.co.uk"}}
          >
            <MenuItem>
              <InfoOutlinedIcon
                sx={{
                  color: "#000000",
                  opacity: 0.8,
                  mr: "12px"
                }}
              />
              <StyledListItemText
                primaryTypographyProps={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "#757575"
                }}
                primary={t('About WYSPR')}
              />
            </MenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none" }} 
            to="/"
            onClick={() => {window.location.href="https://www.wyspr.co.uk/contact-us"}}
          >
            <MenuItem>
              <HelpOutlineOutlinedIcon
                sx={{
                  color: "#000000",
                  opacity: 0.8,
                  mr: "12px"
                }}
              />
              <StyledListItemText
                primaryTypographyProps={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "#757575"
                }}
                primary={t('Get in touch')}
              />
            </MenuItem>
          </Link>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{
              color: "#000000",
              opacity: 0.8,
              mr: "12px"
            }}
            />
            <StyledListItemText
              primaryTypographyProps={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "19.36px",
                color: "#757575"
              }}
              primary={t('Logout')}
            />
          </MenuItem>
        </MenuListWrapperPrimary>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
