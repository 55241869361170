import { useContext } from 'react';
import Scrollbar from 'src/components/Shared/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  styled,
  Divider,
  useTheme,
} from '@mui/material';

import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
`
);

function Sidebar(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: "#051e34",
          boxShadow: "0px 4px 3px #CCCEDD, 3px 0px 4px rgba(239, 239, 239, 0.5)",
          // borderRadius: "0px 6px 6px 0px"
        }}
      >
        <Scrollbar>
          <SidebarTopSection />
          <Divider
            sx={{
              mt: "20px",
              mb: "45px",
              // mx: theme.spacing(3.5),
              background: "#F6F8FB"
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <SidebarFooter />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <SidebarTopSection />
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(3.5),
                background: "#F6F8FB"
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <SidebarFooter />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
