import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { User } from 'src/models/user';

interface SignupState {
  step: number
}

interface UserState {
  user: User | null,
  signupState: SignupState
}

const initialState: UserState = {
  user: null,
  signupState: {
    step: 0
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    createUserState(state: UserState, action: PayloadAction<User>) {
      if (action.payload) {
        const user = action.payload;
        user.preferredMFA && delete user.preferredMFA;
        user.authenticationFlowType && delete user.authenticationFlowType;
        user.pool && delete user.pool;
        user.client && delete user.client;
        user.storage && delete user.storage;
        user.userDataKey && delete user.userDataKey;
        user.keyPrefix && delete user.keyPrefix;
        state.user = user;
      }
    },
    incrementSignupStateStep(state: UserState) {
      state.signupState.step++;
    },
    setSignupStateStep(state: UserState, action: PayloadAction<SignupState>) {
      state.signupState.step = action.payload.step;
    },
    resetSignupStateStep(state: UserState) {
      state.signupState.step = 0;
    }
  }
});

export const createUserState =
  (data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.createUserState(data));
  };

export const incrementSignupStateStep =
  (): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.incrementSignupStateStep());
  };

export const setSignupStateStep =
  (step: SignupState): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setSignupStateStep(step));
  };

export const resetSignupStateStep =
  (): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.resetSignupStateStep());
  };

export const reducer = slice.reducer;

export default slice;