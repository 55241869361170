import type { ReactNode } from 'react';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
// import ArtTrackTwoToneIcon from '@mui/icons-material/ArtTrackTwoTone';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Diversity3Icon from '@mui/icons-material/Diversity3'
import HubIcon from '@mui/icons-material/Hub';
// import SettingsInputAntennaTwoToneIcon from '@mui/icons-material/SettingsInputAntennaTwoTone';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  name: string;
  wysprOnly: boolean
}

export interface MenuItems {
  items: MenuItem[];
}

export const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        icon: DashboardTwoToneIcon,
        link: '/home',
        wysprOnly: false
      },
      {
        name: 'Paid Campaigns',
        icon: MonetizationOnTwoToneIcon,
        link: '/campaigns',
        wysprOnly: false
      },
      {
        name: 'Non-paid Campaigns',
        icon: CampaignTwoToneIcon,
        link: '/non-paid-campaigns',
        wysprOnly: false
      },
      {
        name: 'Brands',
        icon: BusinessCenterTwoToneIcon,
        link: '/brands',
        wysprOnly: false
      },
      {
        name: 'My List',
        icon: HubIcon,
        link: '/deepLinks',
        wysprOnly: false
      },
      {
        name: 'Clients',
        icon: PeopleAltTwoToneIcon,
        link: '/clients',
        wysprOnly: true
      },
      // {
      //   name: 'Vouchers & articles',
      //   icon: ArtTrackTwoToneIcon,
      //   link: '/vouchers-and-articles',
      // },
      // {
      //   name: 'Broadcasts',
      //   icon: SettingsInputAntennaTwoToneIcon,
      //   link: '/broadcasts',
      // },
      {
        name: 'Creators',
        icon: RecordVoiceOverTwoToneIcon,
        link: '/friendvertisers',
        wysprOnly: true
      },
      {
        name: 'Analytics',
        icon: AnalyticsIcon,
        link: '/analytics',
        wysprOnly: true
      },
      {
        name: 'Referrals',
        icon: Diversity3Icon,
        link: '/referrals',
        wysprOnly: true
      },
    ]
  }
];