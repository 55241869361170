import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/Shared/SuspenseLoader';

import { AccountTypeEnum } from "src/models/user";
import useAuth from 'src/hooks/useAuth';

const Loader = (Component) => (props) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
);

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const WysprRoute = ({ children, roles}: { children: JSX.Element, roles: Array<AccountTypeEnum>}) => {
    const auth = useAuth();
    const wysprAccountType =  auth.user.accountType === AccountTypeEnum.WYSPR && roles.includes(auth.user.accountType) ? true : false;

    if (auth.isAuthenticated && !wysprAccountType) {
        return <Status404 />
    }
    return children;
};

export default WysprRoute;
