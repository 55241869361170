import { CurrencyEnum } from "./payments";
import { GenderEnum } from "./user";

export enum UserRoleEnum {
  FRIENDVERTISER = "F",
  INFLUENCER = "I",
}

export enum TargetAgeEnum {
  ALL = "ALL",
  F13T17 = "13-17",
  F18T24 = "18-24",
  F25T34 = "25-34",
  F35T44 = "35-44",
  F45T54 = "45-54",
  F55T64 = "55-64",
  F65 = "65+",
}

export interface Friendvertiser {
  id?: string;
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  clientTeam?: string;
  accountState: string;
  mobileNumber: string;
  requestScreenshot?: boolean;
  appUsage? :number;
  avatarUrl?: {
    image96x96?: string;
    image375x375?: string;
  };
  socialMedia: {
    instagram: {
      username: string;
      followers?: number;
      impressions?: number;
      reach?: number;
      profileVisits?: number;
      posts?: number;
      audience?: {
        summary: {
          gender: {
            category: GenderEnum;
            value: number;
          };
          age: {
            category: TargetAgeEnum;
            value: number;
          };
          engagement: {
            mean: number;
            avgDeviation: number;
          };
        };
        city: {
          name: string;
          weight: number;
        };
        country: {
          name: string;
          weight: number;
        };
        gender: {
          male: number;
          female: number;
        };
        age: {
          F13T17: number;
          F18T24: number;
          F25T34: number;
          F35T44: number;
          F45T54: number;
          F55T64: number;
          F65: number;
        };
        followers?: number;
      };
      profileScreenshots: {
        fileName: string;
        image96x96: string;
        image375x375: string;
      }[];
    };
  };
}

export interface FriendvertiserPayment {
  friendvertiserId: string;
  campaignId: string;
  recipientType?: string;
  emailMessage?: string;
  emailSubject?: string;
  note: string;
  currency: CurrencyEnum;
  isPerformanceBased?: boolean
}

export interface FriendvertiserInsights {
  audience: {
    city: { name: string; value: number };
    country: { name: string; value: number };
    engagement: { value: number; rate: number };
    views: { value: number; rate: number };
    age: { category: string; value: number };
    gender: { category: string; value: number };
  };
  avgPayment: number;
  followers: number;
  jobCount: number;
  applications?:{ [x: string]: string }[]
  appliedCount?: number;
}



export enum FriendvertiserMapBackendEnum {
  USERNAME = 'USERNAME',
  FOLLOWERS = 'FOLLOWERS',
  AVGENGAGEMENT = 'AVG_ENGAGEMENT',
  AUDIENCEAGE = 'AUDIENCE',
  AUDIENCEGENDER = 'GENDER',
  STATUS = 'STATUS',
  ROLE = 'ROLE',
  SOURCE = 'SOURCE'
}