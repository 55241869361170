import { Box } from '@mui/material';
import { useEffect } from 'react';
import anime from "animejs";

function AppInit() {
  useEffect(() => {
    anime({
      targets: '.line-drawing-demo .lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1500,
      delay: function(el, i) { return i * 100 },
      direction: 'alternate',
      loop: true
    });
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className="line-drawing-demo">
        <svg width="100%" height="100%" viewBox="0 0 1800 500">
          <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="3" className="lines">
            <path className="el"
              d="M50.41,61.191l25.182,0l53.828,307.03l106.732,-307.03l24.258,0l109.736,310.033l61.221,-310.033l23.333,0l-66.766,330.363l-35.115,-0l-104.422,-301.716l-104.654,301.716l-34.422,-0l-58.911,-330.363Z" />
            <path className="el"
              d="M482.192,61.191l28.877,0l106.964,191.749l110.198,-191.749l26.567,0l-123.828,214.851l0,115.512l-24.257,-0l-0,-115.512l-124.521,-214.851Z" />
            <path className="el"
              d="M797.537,380.003l0,-25.413c34.654,12.321 72.85,18.482 114.588,18.482c69.153,0 103.729,-26.183 103.729,-78.548c-0,-38.503 -22.486,-57.755 -67.459,-57.755l-67.92,-0c-61.452,-0 -92.178,-27.723 -92.178,-83.169c-0,-63.146 45.049,-94.719 135.148,-94.719c36.963,0 72.772,4.621 107.425,13.862l0,23.102c-34.653,-10.781 -70.462,-16.172 -107.425,-16.172c-73.927,0 -110.891,24.643 -110.891,73.927c-0,40.044 22.64,60.066 67.921,60.066l67.92,0c61.144,0 91.716,26.953 91.716,80.858c0,66.227 -42.662,99.34 -127.986,99.34c-41.738,0 -79.934,-4.62 -114.588,-13.861Z" />
            <path className="el"
              d="M1102.49,391.554l0,-330.363l138.614,0c64.378,0 96.567,30.264 96.567,90.792c0,59.604 -51.518,97.569 -154.554,113.894l-4.158,-22.178c87.788,-12.167 131.683,-42.123 131.683,-89.867c-0,-47.437 -24.489,-71.155 -73.465,-71.155l-110.429,-0l-0,308.877l-24.258,-0Z" />
            <path className="el"
              d="M1394.27,391.554l-0,-330.363l136.303,0c59.758,0 89.637,26.414 89.637,79.241c0,45.743 -32.42,78.163 -97.26,97.261l111.584,153.861l-33.268,-0l-109.735,-153.168l-0,-16.403c67.766,-10.011 101.649,-36.578 101.649,-79.703c0,-39.736 -22.178,-59.603 -66.534,-59.603l-108.119,-0l0,308.877l-24.257,-0Z" />
          </g>
        </svg>
      </Box>
    </Box>
  );
}

export default AppInit;
