import { Box, List, styled } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import SidebarMenuItem from "./item";
import { menuItems, MenuItem, MenuItems } from "./items";
import { AccountTypeEnum } from "src/models/user";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 4px 0px 4px 0px;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          border-radius: 5px;
          display: flex;
          color: #0E8AD1;
          font-family: Roboto;
          font-weight: 500;
          font-size: 15px;
          line-height: 18.75px;
          background-color: transparent;
          width: 100%;
          margin-right: 10px;
          margin-left: 10px;
          justify-content: flex-start;
          padding: ${theme.spacing(1.5, 1.5)};

          .MuiButton-startIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: #0E8AD1;
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          &.Mui-active,
          &:hover {
            background-color: #00304E;
            color: white;

            .MuiButton-startIcon {
              color: white;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
  props,
}: {
  items: MenuItem[];
  path: string;
  props: any;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce(
        (ev, item) => reduceChildRoutes({ ev, item, path, props }),
        []
      )}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item,
  props,
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
  props: any;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  ev.push(
    <SidebarMenuItem
      key={key}
      active={exactMatch}
      name={item.name}
      link={item.link}
      badge={item.badge}
      badgeTooltip={item.badgeTooltip}
      icon={item.icon}
    />
  );
  return ev;
};

const Menu = (items: MenuItems[], props) => {
  const location = useLocation();

  return items.map((section, index) => (
    <MenuWrapper key={index}>
      <List component="div">
        {renderSidebarMenuItems({
          items: section.items,
          path: location.pathname,
          props,
        })}
      </List>
    </MenuWrapper>
  ));
};

function SidebarMenu(props) {
  const auth = useAuth();
  const renderItems = (items: MenuItem[]) => {
    const filteredItems =
      auth.user.accountType === AccountTypeEnum.CLIENT
        ? items.filter((item) => !item.wysprOnly)
        : items;
    return [{ items: filteredItems }];
  };
  return <>{Menu(renderItems(menuItems[0].items), props)}</>;
}

export default SidebarMenu;
