import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Brand, Sort, IndustriesEnum } from "src/models/brand";
import { ClientTeam } from "src/models/clientTeam";
import { RootState } from "src/store";
import { setAlertMessage } from "./alert";
import {
  getBrands,
  GetBrandsInput,
  editBrand,
  deleteBrand,
  EditBrandRequest,
  CreateBrandRequest,
  createBrand,
} from "src/api/brands";
import { getClientTeams } from "src/api/clientTeam";


interface BrandState {
  brand: Brand[];
  pageInfo: {
    totalPages: number;
    currentPage: number;
    totalDocs: number;
  };
  loading: boolean;
  error: string;
  sort: Sort;
  page: number;
  isDrawerOpen: boolean;
  editForm: boolean;
  editBrandDetails: Brand | {};
  organisation: ClientTeam[] | [];
  industries: Array<keyof typeof IndustriesEnum>
}

const initialState: BrandState = {
  brand: [],
  pageInfo: {
    totalPages: 0,
    currentPage: 0,
    totalDocs: 0
  },
  loading: false,
  error: "",
  sort: { sortBy: "", order: "" },
  page: 1,
  isDrawerOpen: false,
  editForm: false,
  editBrandDetails: {},
  organisation: [],
  industries: []
};

interface GetBrandOutput {
  brand: Brand[];
  pageInfo: {
    totalPages: number;
    currentPage: number;
    totalDocs: number;
  };
}

export const fetchBrands = createAsyncThunk<GetBrandOutput, GetBrandsInput>(
  "brands/fetch",
  async ({ pageNumber, limit, order, sortBy }, thunkAPI) => {
    try {
      const response = await getBrands({ pageNumber, limit, order, sortBy })
      return {
        brand: response.brands,
        pageInfo: response.pageInfo
      };
    }
    catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message:
            "There was an error when trying to get the page data. Please try again later.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const deleteBrands = createAsyncThunk<void, { brandId: string; userId: string; teamId: string }>(
  'brands/deleteBrand',
  async ({ brandId, userId, teamId }, thunkAPI) => {
    try {
      await deleteBrand(brandId, userId, teamId);
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to delete this brand. Please ensure that this brand has no active campaigns before deleting.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const editBrands = createAsyncThunk<void, { brandId: string; input: EditBrandRequest }>(
  'brands/editBrand',
  async ({ brandId, input }, thunkAPI) => {
    try {
      await editBrand(brandId, input);
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to edit this brand. Please try again later.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const fetchClientTeams = createAsyncThunk<ClientTeam[], number>(
  "brands/clientTeams/fetch",
  async (limit, thunkAPI) => {
    try {
      const response = await getClientTeams(limit);
      return response.clientTeamData;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to fetch client teams. Please try again later.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const createBrands = createAsyncThunk<Brand, CreateBrandRequest>(
  "brands/createBrand",
  async (input, thunkAPI) => {
    try {
      const response = await createBrand(input);
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to create the brand. Please try again later.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload
    },
    setEditForm: (state, action: PayloadAction<boolean>) => {
      state.editForm = action.payload
    },
    setEditBrandDetails: (state, action: PayloadAction<Brand>) => {
      state.editBrandDetails = action.payload
    },
    setIndustries: (state, action: PayloadAction<Array<keyof typeof IndustriesEnum>>) => {
      state.industries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getBrands
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false
        state.brand = action.payload.brand
        state.pageInfo = action.payload.pageInfo
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false
        state.brand = []
        state.error = action.error.message
      })
      // deleteBrands
      .addCase(deleteBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBrands.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // editBrands 
      .addCase(editBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBrands.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // fetchClientTeams
      .addCase(fetchClientTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClientTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.organisation = action.payload;
      })
      .addCase(fetchClientTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // createBrands
      .addCase(createBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBrands.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
})

export const reducer = brandSlice.reducer
export const {
  setSort,
  setPage,
  setIsDrawerOpen,
  setEditForm,
  setEditBrandDetails,
  setIndustries
} = brandSlice.actions
//BrandsManagement
export const selectBrands = (state: RootState) => state.brand.brand
export const selectBrandStatus = (state: RootState) => state.brand.loading
export const selectBrandPageInfo = (state: RootState) => state.brand.pageInfo
export const selectBrandSort = (state: RootState) => state.brand.sort
export const selectBrandPage = (state: RootState) => state.brand.page
export const selectBrandIsDrawerOpen = (state: RootState) => state.brand.isDrawerOpen
export const selectBrandEditForm = (state: RootState) => state.brand.editForm
export const selectBrandEditBrandDetails = (state: RootState) => state.brand.editBrandDetails
// BrandDrawers
export const selectBrandOrganisation = (state: RootState) => state.brand.organisation
export const selectBrandIndustries = (state: RootState) => state.brand.industries