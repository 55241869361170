import {
  Box,
} from '@mui/material';
import WysprLogo from 'src/components/Shared/Logo/WysprLogo';

function SidebarTopSection() {

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 3,
        pt: 2,
        position: 'relative'
      }}
    >
      <WysprLogo style={{ width: "118px" }} />
    </Box>
  );
}

export default SidebarTopSection;
