import { SVGProps } from "react";
import { Box } from '@mui/material';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
    <Box 
        width="fit-content"
        sx={{
            p: "5px",
            pb: "3px",
            // border: 1,
            // borderColor: "#CCCCCC"
    }}>
        <svg
            viewBox="0 0 1676 460"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
            }}
            {...props}
        >
            <path
            style={{
                fill: "none",
            }}
            d="M0 0h1675.91v459.841H0z"
            />
            <path
            fill = "white"
            d="M50.41 61.191h25.182l53.828 307.03 106.732-307.03h24.258l109.736 310.033 61.221-310.033H454.7l-66.766 330.363h-35.115L248.397 89.838 143.743 391.554h-34.422L50.41 61.191Zm431.782 0h28.877L618.033 252.94 728.231 61.191h26.567L630.97 276.042v115.512h-24.257V276.042L482.192 61.191Zm315.345 318.812V354.59c34.654 12.321 72.85 18.482 114.588 18.482 69.153 0 103.729-26.183 103.729-78.548 0-38.503-22.486-57.755-67.459-57.755h-67.92c-61.452 0-92.178-27.723-92.178-83.169 0-63.146 45.049-94.719 135.148-94.719 36.963 0 72.772 4.621 107.425 13.862v23.102c-34.653-10.781-70.462-16.172-107.425-16.172-73.927 0-110.891 24.643-110.891 73.927 0 40.044 22.64 60.066 67.921 60.066h67.92c61.144 0 91.716 26.953 91.716 80.858 0 66.227-42.662 99.34-127.986 99.34-41.738 0-79.934-4.62-114.588-13.861Zm304.953 11.551V61.191h138.614c64.378 0 96.567 30.264 96.567 90.792 0 59.604-51.518 97.569-154.554 113.894l-4.158-22.178c87.788-12.167 131.683-42.123 131.683-89.867 0-47.437-24.489-71.155-73.465-71.155h-110.429v308.877h-24.258Zm291.78 0V61.191h136.303c59.758 0 89.637 26.414 89.637 79.241 0 45.743-32.42 78.163-97.26 97.261l111.584 153.861h-33.268l-109.735-153.168v-16.403c67.766-10.011 101.649-36.578 101.649-79.703 0-39.736-22.178-59.603-66.534-59.603h-108.119v308.877h-24.257Z"
            style={{
                fillRule: "nonzero",
            }}
            />
        </svg>
    </Box>
);

export default SVGComponent;
