import { FC, ReactNode, useState } from 'react'; 
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { AccountTypeEnum } from 'src/models/user';
import { useDispatch as useReduxDispatch } from 'src/store';
import { setSignupStateStep } from 'src/slices/user';
import { setAlertMessage } from 'src/slices/alert';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const reduxDispatch = useReduxDispatch();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const setSignupFlowStep = (step: number) => {
    reduxDispatch(setSignupStateStep({
      step: step
    }));
    return;
  }

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to='/account/login' />;
  }

  if (auth.user.accountType !== AccountTypeEnum.CLIENT && auth.user.accountType !== AccountTypeEnum.WYSPR && auth.user.accountType !== AccountTypeEnum.UNDEFINED) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to='/account/notclient' />;
  }

  if (!auth.isAccepted &&
      auth.user.accountType !== AccountTypeEnum.CLIENT &&
      auth.user.accountType !== AccountTypeEnum.WYSPR &&
      auth.user.accountType === AccountTypeEnum.UNDEFINED
    ) {

    // TODO Make API call to check if user invite exists
    // If client user joining by invite - acceptClientTeamInviteWithToken()
    // If WYSPR user - acceptWysprTeamInviteWithToken()

    // If client user creating a client team
    setSignupFlowStep(1);
    reduxDispatch(setAlertMessage({
      message: "Your account profile is not complete. Please fill out the remaining forms before gaining access to the platform.",
      severity: "info"
    }));
    return <Navigate to='/account/register' />;

  }

  if (!auth.isAccepted) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to='/account/notaccepted' />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
