import { WYSPR_WEBAPI_HOST, Response } from '../index';
import axios from 'axios';
import { SingleCampaignInsights, IUserDataAnalytics } from 'src/models/insights';

export interface CampaignInsights {
  APPROVED?: number;
  REJECTED?: number;
  PENDING?: number;
  DRAFT?: number;
  CLOSED?: number
}

export interface UserAccountStates {
  ACCEPTED?: number;
  DECLINED?: number;
  PENDING?: number;
  ARCHIVED?: number;
}

export type UserInsights = {
  accountState?: UserAccountStates
  autoVerified?: number
}


/*
Get Campaign insights. Count of different campaign states
*/
export const getCampaignInsights = async (): Promise<CampaignInsights> => {
  const response: Response<{
    message: {
      campaignData: CampaignInsights
    }
  }> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/campaigns?count=true`
  )
  return response.data.message.campaignData;
}

/*
Get Campaign insight metrics
*/
export const getCampaignSingleInsights = async (campaignId: string): Promise<SingleCampaignInsights> => {
  const response: Response<{
    message: {
      campaignData: SingleCampaignInsights

    }
  }> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/campaigns/${campaignId}/insights`,
  );
  return response.data.message.campaignData;
};

/*
Get Client insights. Count of different client states
*/
export const getClientInsights = async (): Promise<UserInsights> => {
  const response: Response<{
    message: {
      userData: UserInsights
    }
  }> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/users?accountType=CLIENT&count=true`
  )
  return response.data.message.userData
}

/*
Get Friendvertiser insights. Count of different account states
*/
export const getFriendvertiserInsights = async (): Promise<UserInsights> => {
  const response: Response<{
    message: {
      userData: UserInsights
    }
  }> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/users?accountType=FRIENDVERTISER&count=true`
  )
  return response.data.message.userData
}

/*
Get user insights to populate analytics page.
*/
export const analyticsInsights = async (dateRange: string): Promise<IUserDataAnalytics> => {
  const response: Response<{
    message: {
      userData: IUserDataAnalytics
    }
  }> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/users?accountType=FRIENDVERTISER&dateRange=${dateRange}&analytics=true`
  )
  return response.data.message.userData
}