import { useRef, useState } from 'react';

import {
  alpha,
  Box,
  IconButton,
  Popover,
  styled,
  Tooltip,
  useTheme
} from '@mui/material';

import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
  margin-left: 10px;
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonWrapper
          sx={{
            background: "transparent",
            transition: `${theme.transitions.create(['background'])}`,
            color: theme.general.wysprBlue,

            '&:hover': {
              background: alpha(theme.colors.primary.main, 0.2)
            }
          }}
          color="primary"
          ref={ref}
          onClick={handleOpen}
        >
          <NotificationsNoneTwoToneIcon fontSize="small" />
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={440} maxWidth={440} p={2}>
          Notifications
        </Box>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
