import axios from "axios";
import { LinkEventStat } from "src/models/dynamicLink";
import { WYSPR_WEBAPI_HOST, Response } from "../index";
import { AccountValidationEnum } from "../user";

export interface DynamicLinkResponse {
  message: {
    data: { [x: string]: string }[];
  };
}
export interface IReferral {
  _id: string;
  creatorCount: number;
  name: string;
  linkData: string;
  generatedLink: string;
  clientTeamId: string;
}
export interface GetReferralPromise {
  message: IReferral[];
}

export interface DynamicLinkResponse {
  message: {
    data: { [x: string]: string }[];
  };
}

export interface Friendvertiser {
  lastName: string;
  email: string;
  firstName: boolean;
  friendvertiserData: {};
}

export interface FriendvertiserLink {
  name: string;
  _id: string;
  friendvertisers: Friendvertiser[];
}

export interface DynamicAutoList {
  friendvertiserReferralList: FriendvertiserLink[];
  linkData: {
    linkEventStats: LinkEventStat[];
  };
}

export interface ReferralPaymentManagement {
  firstName: string;
  lastName: string;
  referrerPayPalEmail: string;
  _id: string;
  email: string;
  instagramHandle: string;
  referred: ReferredUser;
  referredEmail: string;
  referredStatus: AccountValidationEnum;
}
export interface ReferralPaymentAnalytics {
  paymentStatus: ReferralPaymentStatusEnum;
  total: number;
}

export interface ReferredUser {
  _id: string;
  userRole: string;
  requestScreenshot: boolean;
  appUsage: number;
  socialMedia: SocialMedia;
  proAccountRequest: boolean;
  referralPayment: IReferredPayment;
}

export interface IReferredPayment {
  batchId: string;
  status?: ReferralPaymentStatusEnum;
}

export enum ReferralPaymentStatusEnum {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PAYMENT_SENT = "PAYMENT_SENT",
  PAYMENT_ACCEPTED = "PAYMENT_ACCEPTED",
  PAYMENT_DECLINED = "PAYMENT_DECLINED",
  PAYMENT_UNCLAIMED = "PAYMENT_UNCLAIMED",
  PAYMENT_REFUNDED = "PAYMENT_REFUNDED",
  PAYMENT_OUTSTANDING = "PAYMENT_OUTSTANDING"
}

export interface SocialMedia {
  instagram: Username;
  tikTok: Username;
}

export interface Username {
  username: string;
}

/*
  GET Used to list of dynamic links linked to Client.
*/
export const getLinkStats = async (
  clientId: string
): Promise<DynamicAutoList> => {
  const response: Response<any> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/dynamicLinks/${clientId}`
  );
  return response.data.message;
};

/*
  GET Used to list of dynamic links linked to Client.
*/
export const getReferrals = async (clientId?: string): Promise<IReferral[]> => {
  const response: Response<GetReferralPromise> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/dynamicLinks`,
    {
      params: {
        ...(clientId ? { clientId: clientId } : {})
      }
    }
  );
  return response.data.message;
};

/*
  GET Used to list of outstanding referrals linked to users
*/

export const getReferralPaymentManagement = async <T>(
  url?: string
): Promise<T[]> => {
  const response = await axios.get(`${WYSPR_WEBAPI_HOST}/${url}`);
  return response.data.message as unknown as T[];
};

/*
  PUT used to update referer payment
*/
export const refererPayment = async (referredId: string): Promise<any> => {
  const response: Response<{
    message: any;
  }> = await axios.put(`${WYSPR_WEBAPI_HOST}/dynamicLinks/payment`, {
    referredId
  });
  return response.data;
};

export const createDynamicLink = async ({
  title,
  linkUrl,
  clientTeamId
}): Promise<DynamicLinkResponse> => {
  const response: Response<DynamicLinkResponse> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/dynamicLink`,
    { title, linkUrl, clientTeamId }
  );
  
  return response.data;
};