import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import SuspenseLoader from "src/components/Shared/SuspenseLoader";

// User Routes
import { AccountTypeEnum } from "src/models/user";
import WysprRoute from "./wysprRoute";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import("src/content/dashboards/Home")));
const Campaigns = Loader(
  lazy(() => import("src/content/dashboards/Campaigns"))
);
const CreateCampaign = Loader(
  lazy(() => import("src/content/dashboards/Campaigns/CreateCampaign"))
);
const CreateNonPaidCampaign = Loader(
  lazy(
    () => import("src/content/dashboards/Campaigns/CreateNonPaidCampaign")
  )
);
const Brands = Loader(lazy(() => import("src/content/dashboards/Brands")));
const Friendvertisers = Loader(
  lazy(() => import("src/content/dashboards/Friendvertisers"))
);
const Clients = Loader(lazy(() => import("src/content/dashboards/Clients")));
// const VouchersAndArticles = Loader(
//   lazy(() => import("src/content/dashboards/VouchersAndArticles"))
// );
// const Broadcasts = Loader(
//   lazy(() => import("src/content/dashboards/Broadcasts"))
// );

const Analytics = Loader(lazy(() => import("src/components/Analytics")));

const DynamicLinkManagement = Loader(
  lazy(() => import("src/components/DynamicLinkManagement"))
);

const Referrals = Loader(
  lazy(() => import("src/content/dashboards/Referrals"))
);

const dashboardsRoutes = [
  {
    path: "/",
    element: <Navigate to="home" replace />,
  },
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "campaigns",
    element: <Campaigns isPaidCampaign={true} />,
  },
  {
    path: "campaigns/create/",
    element: <CreateCampaign />,
  },
  {
    path: "campaigns/create/:campaignId",
    element: <CreateCampaign />,
  },
  {
    path: "non-paid-campaigns",
    element: (
        <Campaigns isPaidCampaign={false} />
    )
  },
  {
    path: "non-paid-campaigns/create/",
    element: (
        <CreateNonPaidCampaign />
    )
  },
  {
    path: "non-paid-campaigns/create/:campaignId",
    element: (
        <CreateNonPaidCampaign />
    )
  },
  {
    path: "brands",
    element: <Brands />,
  },
  {
    path: "deepLinks",
    element: <DynamicLinkManagement />,
  },
  {
    path: "friendvertisers",
    element: (
      <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
        <Friendvertisers />
      </WysprRoute>
    ),
  },
  {
    path: "clients",
    element: (
      <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
        <Clients />
      </WysprRoute>
    ),
  },
  // {
  //   path: "vouchers-and-articles",
  //   element: (
  //     <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
  //       <VouchersAndArticles />
  //     </WysprRoute>
  //   ),
  // },
  // {
  //   path: "broadcasts",
  //   element: (
  //     <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
  //       <Broadcasts />
  //     </WysprRoute>
  //   ),
  // },
  {
    path: "analytics",
    element: (
      <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
        <Analytics />
      </WysprRoute>
    ),
  },
  {
    path: "referrals",
    element: (
      <WysprRoute roles={[AccountTypeEnum.WYSPR]}>
        <Referrals />
      </WysprRoute>
    ),
  },
];

export default dashboardsRoutes;
