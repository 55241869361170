import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';
import { User } from 'src/models/user';
import { getUserWithToken, AccountValidationEnum } from 'src/api/user';

import { useDispatch as useReduxDispatch } from 'src/store';
import { persistor } from 'src/store';
import { createUserState } from 'src/slices/user';
import { setWelcomeBoxShow } from 'src/slices/dashboard';

Amplify.configure(amplifyConfig);

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isAccepted: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  register: (email: string, password: string, first_name: string, last_name: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  passwordChange: (
    oldPassword: string,
    newPassword: string,
  ) => Promise<void>;
  getAuthenticatedUser: () => Promise<any>;
  setAccepted: () => Promise<void>;
  emailChange: (newEmail: string) => Promise<void>;
  reinitialize: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    isAccepted: boolean;
    user: User | null;
  };
};

type AcceptAction = {
  type: 'ACCEPT';
  payload: {
    isAccepted: boolean;
  };
};


type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAccepted: boolean;
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type PasswordChangeAction = {
  type: 'PASSWORD_CHANGE';
};

type EmailChangeAction = {
  type: 'EMAIL_CHANGE';
};

type Action =
  | InitializeAction
  | LoginAction
  | AcceptAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | PasswordChangeAction
  | EmailChangeAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isAccepted: false,
  isInitialized: false,
  user: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, isAccepted, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isAccepted,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, isAccepted } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isAccepted,
      user
    };
  },
  ACCEPT: (state: AuthState, action: AcceptAction): AuthState => {
    const { isAccepted } = action.payload;

    return {
      ...state,
      isAccepted,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_CHANGE: (state: AuthState): AuthState => ({ ...state }),
  EMAIL_CHANGE: (state: AuthState): AuthState => ({ ...state }),
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve({ email: undefined, first_name: undefined, last_name: undefined }),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  passwordChange: () => Promise.resolve(),
  getAuthenticatedUser: () => Promise.resolve(),
  setAccepted: () => Promise.resolve(),
  emailChange: () => Promise.resolve(),
  reinitialize: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const reduxDispatch = useReduxDispatch();

  const fetchUserState = async () => {
    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    reduxDispatch(createUserState(user));

    let isAccepted: boolean = false;
    const dbUser = await getUserWithToken(user.attributes.sub, user.signInUserSession.idToken.jwtToken, true); // Throws error if HTTP 401 Unauthorized
    if (dbUser.accountState === AccountValidationEnum.ACCEPTED) {
      isAccepted = true;
    }

    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        isAccepted,
        user: {
          id: user.username,
          token: user.signInUserSession.accessToken,
          email: user.attributes.email,
          email_verified: user.attributes.email_verified,
          first_name: user.attributes.first_name,
          last_name: user.attributes.last_name,
          ...dbUser
        }
      }
    });
  }

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        await fetchUserState();
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isAccepted: false,
            user: null,
          }
        });
      }
    };

    initialize();
  }, [reduxDispatch]);

  const setAccepted = async (): Promise<void> => {
    dispatch({
      type: 'ACCEPT',
      payload: {
        isAccepted: true,
      }
    });
  }

  const getAuthenticatedUser = async (): Promise<any> => {
    try {
      await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  const login = async (email: string, password: string): Promise<User> => {
    const user = await Auth.signIn(email, password);
    reduxDispatch(createUserState(user));
    let isAccepted: boolean = false;

    const dbUser = await getUserWithToken(user.attributes.sub, user.signInUserSession.idToken.jwtToken, true);
    if (dbUser.accountState === AccountValidationEnum.ACCEPTED) {
      isAccepted = true;
    }

    if (user.challengeName) {
      console.error(`Can't login, "${user.challengeName}" failed.`);
      return;
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        isAccepted,
        user: {
          id: user.sub,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          email_verified: user.attributes.email_verified,
          ...dbUser
        }
      }
    });

    return user;
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    reduxDispatch(setWelcomeBoxShow(true));
    persistor.purge();
    // localStorage.removeItem("persist:root");
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email: string, password: string, first_name: string, last_name: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email, name: first_name, family_name: last_name }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  const passwordChange = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    dispatch({
      type: 'PASSWORD_CHANGE'
    });
  };

  const emailChange = async (
    newEmail: string,
  ): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      email: newEmail
    });
    dispatch({
      type: 'EMAIL_CHANGE'
    });
  };

  const reinitialize = async (): Promise<void> => {
    await fetchUserState();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        passwordChange,
        getAuthenticatedUser,
        setAccepted,
        emailChange,
        reinitialize
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
