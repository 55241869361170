import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import {
  refererPayment
} from "src/api/dynamicLink";
import { setAlertMessage } from "./alert";


interface ReferralState {
  selectedUser: string;
  firstName: string | undefined;
  lastName: string | undefined;
  openModal: boolean;
  loading: boolean;
  error: string;
}

const initialState: ReferralState = {
  selectedUser: "",
  firstName: undefined,
  lastName: undefined,
  openModal: false,
  loading: false,
  error: "",
}

// To update with WF-47 types
export const refererPaymentAsync = createAsyncThunk<string, any>(
  "referrals/refererPayment",
  async (selectedUser, thunkAPI) => {
    try {
      await refererPayment(selectedUser);
    } catch (error) {
      thunkAPI.dispatch(
        setAlertMessage({
          message: "An error occurred when trying to get the page data. Please try again later.",
          severity: "error"
        })
      );
      return thunkAPI.rejectWithValue(error.message || "An error occurred");
    }
  }
);


const referralSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<string>) => {
      state.selectedUser = action.payload
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(refererPaymentAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(refererPaymentAsync.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(refererPaymentAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error?.message
      })
  }
})



export const reducer = referralSlice.reducer
export const { setSelectedUser, setFirstName, setLastName, setOpenModal } = referralSlice.actions
export const selectReferralloading = (state: RootState) => state.referrals.loading
export const selectSelectedUser = (state: RootState) => state.referrals.selectedUser
export const selectFirstName = (state: RootState) => state.referrals.firstName
export const selectLastName = (state: RootState) => state.referrals.lastName
export const selectOpenModal = (state: RootState) => state.referrals.openModal