import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';

const AxiosInterceptor = ({ children }): JSX.Element => {
    const navigate = useNavigate();
    const auth = useAuth();

    const requestInterceptor = async (config) => {
        const user = await auth.getAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    }

    const errInterceptor = async (error) => {
        navigate('/account/login');
        return Promise.reject(error);
    }

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(requestInterceptor, errInterceptor);
        return () => axios.interceptors.request.eject(interceptor);
    }, []);

    return children;
}

export default AxiosInterceptor;