import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/Shared/AppInit';

import './App.css';
import 'typeface-roboto';
import { Helmet } from 'react-helmet-async';
import AlertMessage from './components/Shared/AlertMessage';
import { useSelector, useDispatch } from 'src/store';
import { hideAlertMessage } from "src/slices/alert";

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const dispatch = useDispatch();

  const { show, message, severity } = useSelector(
    (state) => state.alert
  );

  return (
    <>
      { process.env.REACT_APP_STAGE === "qa" ?
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `
                window.markerConfig = {
                  destination: '6203c61d8049a4612790e5ea',
                  source: 'snippet'
                };
              `
            },
            {
              type: "text/javascript",
              innerHTML: `
                !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
              `
            }
          ]}
        >
        </Helmet>
      : null}
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CssBaseline />
            <AlertMessage
              showAlertMessage={show}
              alertMessage={{message: message, severity: severity}}
              setShowAlertMessage={() => {dispatch(hideAlertMessage())}}
            />
            {auth.isInitialized ? content : <AppInit />}
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
