import axios from 'axios';
import { WYSPR_WEBAPI_HOST, Response } from '../index';
import { ClientTeam, ClientTeamTransactions, WalletModel } from '../../models/clientTeam';
import { CurrencyEnum } from 'src/models/payments';

export interface GetClientTeamRequest {
  _id: string;
  showMembers?: boolean;
  showClientInfo?: boolean;
  showWallet?: boolean;
}

export interface ClientTeamResponse {
  message: {
    clientTeamData: ClientTeam
  }
}

export interface CreateClientTeamInvite {
  email: string,
  role: string,
  clientTeamId: string,
}

export interface DeleteClientTeamInvite {
  inviteId: string,
  clientTeamId: string,
}

export interface DeleteClientTeamMember {
  _id: string,
  clientTeamId: string,
  status: string
}

export interface GetClientInputs {
  pageNumber?: number;
  limit?: number;
}

export interface GetClientsResponse {
  message: { clientTeamData: { docs: ClientTeam[]; } }
}
export interface GetClientsTransactionsResponse {
  message: { transactionData: { docs: ClientTeamTransactions[]; } }
}

export interface CreditClientTeamWallet {
  clientTeamId: string,
  amount: number,
  reference: string
}

/*
  GET Used to get a client team.
*/
export const getClientTeam = async (clientTeam: GetClientTeamRequest): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeam._id}`,
    {
      params: {
        showMembers: clientTeam.showMembers ? clientTeam.showMembers : false,
        showClientInfo: clientTeam.showClientInfo ? clientTeam.showClientInfo : false,
        showWallet: clientTeam.showWallet ? clientTeam.showWallet : false,
      }
    }
  );
  return response.data.message.clientTeamData;
};
/*
  GET Used to get a client teams.
*/
export const getClientTeams = async (limit: number = 10): Promise<{
  clientTeamData: ClientTeam[];
}> => {
  const response: Response<GetClientsResponse> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/client-teams?showUser=True&showClientInfo=true&page=1&limit=${limit}`,
  );
  return { clientTeamData: response.data.message.clientTeamData.docs }
};

/*
  POST Used to create a new client team.
*/
export const createClientTeam = async (clientTeam: Partial<ClientTeam>): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/client-teams`, clientTeam);
  return response.data.message.clientTeamData;
};

/*
  PUT Used to update a client team.
*/
export const updateClientTeam = async (clientTeam: Partial<ClientTeam>): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.put(
    `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeam._id}`,
    {
      ...clientTeam
    }
  );
  return response.data.message.clientTeamData;
};

/*
  POST Used to send a new invite for a client team.
*/
export const createClientTeamInvite = async (clientTeam: CreateClientTeamInvite): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeam.clientTeamId}/invites`,
    {
      email: clientTeam.email,
      role: clientTeam.role
    }
  );
  return response.data.message.clientTeamData;
};

/*
  DELETE Used to delete sent invites.
*/
export const deleteClientTeamInvite = async (clientTeam: DeleteClientTeamInvite): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.delete(
    `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeam.clientTeamId}/invites?inviteId=${clientTeam.inviteId}`
  );
  return response.data.message.clientTeamData;
};

/*
  PUT Used to manage users from a client team.
*/
export const manageClientTeamMember = async (clientTeam: DeleteClientTeamMember): Promise<ClientTeam> => {
  const response: Response<ClientTeamResponse> = await axios.put(
    `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeam.clientTeamId}/members/${clientTeam._id}`,
    {
      status: clientTeam.status
    }
  );
  return response.data.message.clientTeamData;
};

/*
  POST Used to redeem an invite for a client team.
*/
export const acceptClientTeamInviteWithToken = async (clientTeamId: string, inviteId: string, token: string): Promise<any> => {
  try {
    const noAuthAxios = axios.create(); // Axios instance with no authentication added
    const response: Response<any> = await noAuthAxios.post(
      `${WYSPR_WEBAPI_HOST}/client-teams/${clientTeamId}/members?inviteId=${inviteId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
    );
    return response.data.message.errors;
  } catch (error) {
    if (error.response.status === 400 || error.response.status === 401) {
      throw Error("InviteNotValid");
    } else {
      throw error;
    }
  }
};

// GET Used to get a client team transactions.

export const getTransactions = async (): Promise<{
  clientTeamTransactions: ClientTeamTransactions[];
}> => {
  const response: Response<GetClientsTransactionsResponse> = await axios.get(
    `${WYSPR_WEBAPI_HOST}/payments`,
    {
      params: {
        page: 0,
        limit: 100,
        showPayer: true
      },
    }
  );
  return { clientTeamTransactions: response.data.message.transactionData.docs }
};

/*
  POST Used to credit a client team's wallet.
*/
export const updateClientTeamWallet = async (creditWallet: CreditClientTeamWallet): Promise<WalletModel> => {
  const response: Response<{
    message: {
      walletData: WalletModel,
      errors: any
    }
  }> = await axios.post(
    `${WYSPR_WEBAPI_HOST}/payments/${creditWallet.clientTeamId}/credit`,
    {
      reference: creditWallet.reference,
      amount: creditWallet.amount,
      currency: CurrencyEnum.GBP,
    }
  );
  return response.data.message.walletData;
}
