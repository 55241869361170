export enum CurrencyEnum {
    GBP = "GBP",
    USD = "USD",
    EUR = "EUR"
}

export interface FeeType {
	currency: CurrencyEnum,
	value: number
}

export interface Payment {
    _id: string,
    campaignId: string,
    clientTeamId: string,
    createdAt: string | Date,
    evidenceId: string,
    friendvertiserFee: FeeType
    payee: string,
    payer: string,
    senderBatchId: string,
    senderItemId: string,
    status: string,
    totalFee: FeeType,
    wysprFee: FeeType
}

export interface PaymentAmount {
    clientTeamDebit: number,
    friendvertiserPayout: number,
    payPalPayout: number,
    wysprPayout: number,
    perEngagementFee?: number,
    engRate: number
}