import { Suspense, lazy } from "react";

import SuspenseLoader from "src/components/Shared/SuspenseLoader";
 
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const CampaignOverview = Loader(
  lazy(() => import("src/content/pages/Campaigns/Overview"))
);

const FriendvertiserOverview = Loader(
  lazy(() => import("src/content/pages/Campaigns/FriendvertiserOverview"))
);


export const campaignRoutes = [
  {
    path: "details/:campaignId",
    element: <CampaignOverview isPaidCampaign={true}/>,
  },
  {
    path: "fv-overview/:id/:applicationId/:campaignId",
    element: <FriendvertiserOverview />,
  },
];

export const nonPaidCampaignRoutes = [
  {
    path: "details/:campaignId",
    element: <CampaignOverview isPaidCampaign={false} />
  }
] 