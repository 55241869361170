import ReactDOM from 'react-dom';
import 'src/mocks';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/AmplifyContext';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'src/store';
import AxiosInterceptor from './components/AxiosInterceptor';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <AuthProvider>
              <AxiosInterceptor>
                <App />
              </AxiosInterceptor>
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
